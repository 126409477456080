window.addEventListener('load', ()=>{
    faqUsed();
});

const faqs = document.querySelectorAll('.faq-block .question-item');

function faqUsed() {
    faqs.forEach(faq => {
        const button = faq.querySelector('.question');

        button.addEventListener('click', ()=>{
            faq.classList.toggle('active');
        });
    });
}